import { useAsync, useMountEffect } from "@react-hookz/web";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { config } from "shared/data";

import type { rosettaConfigAppConfigQuery } from "scmp-app/queries/__generated__/rosettaConfigAppConfigQuery.graphql";

export const useRosettaConfig = () => {
  const environment = useRelayEnvironment();
  const [state, actions] = useAsync(async () => {
    const data = await fetchQuery<rosettaConfigAppConfigQuery>(
      environment,
      graphql`
        query rosettaConfigAppConfigQuery {
          appConfig(filter: { entityId: "rosetta" }) {
            json
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    const raw = data.appConfig.json as RosettaConfig;
    return config.general.env === "production" ? raw.production : raw.dev;
  });

  useMountEffect(() => {
    void actions.execute();
  });

  return { rosettaConfigState: state };
};

type RosettaConfig = AppConfig<{
  paywallSettings?: {
    isShowPremiumPromo?: boolean;
    oldArticleTimeframe?: number;
    paywallCampaign?: {
      name?: string;
    };
  };
  posties?: PostiesConfig;
}>;

export type PostiesConfig = {
  disablePaywall?: boolean;
};
