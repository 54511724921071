import { useAsync, useMountEffect } from "@react-hookz/web";
import axios from "axios";
import { useEffect } from "react";
import { useSessionStorage } from "react-use";

import { config } from "shared/data";

export const useNavigatorInfo = () => {
  const [navigatorInfoResponse, setNavigatorInfo] = useSessionStorage<NavigatorInfo>(
    "navigatorInfo",
    undefined,
  );

  const [asyncState, { execute }] = useAsync(async () => {
    const response = await axios.get<NavigatorInfoResponse>(config.navigatorInfo.url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        apiKey: config.navigatorInfo.apiKey,
      },
    });
    return response.data;
  });

  useMountEffect(() => !navigatorInfoResponse && execute());
  useEffect(() => {
    if (navigatorInfoResponse || asyncState.status !== "success") return;
    const parsed: NavigatorInfo = {
      botScore: asyncState?.result?.["Bot-Score"] ?? 0,
      botScoreGroup: getBotScoreGroup(asyncState?.result?.["Bot-Score"] ?? null),
      country: asyncState?.result?.["CF-IPCountry"] ?? "",
      ip: asyncState?.result?.IP ?? "",
      isBot: asyncState?.result?.["IsVerifiedBot"] === true,
      isLightHouseCrawler: asyncState?.result?.["IsLightHouseCrawler"] === true,
    };
    setNavigatorInfo(parsed);
  }, [asyncState?.result, asyncState.status, navigatorInfoResponse, setNavigatorInfo]);

  return {
    data: navigatorInfoResponse,
    state: asyncState,
  };
};

const getBotScoreGroup = (botScore: null | number) => {
  if (!botScore) return "0";

  switch (true) {
    case botScore === 0:
      return "0";
    case botScore < 30:
      return "1-29";
    case botScore < 36:
      return "30-35";
    case botScore < 41:
      return "36-40";
    case botScore < 46:
      return "41-45";
    case botScore < 100:
      return "46-99";
    default:
      return "0";
  }
};

export type NavigatorInfoResponse = {
  "Bot-Score": number;
  "CF-IPCountry": string;
  IP: string;
  IsLightHouseCrawler: boolean;
  IsVerifiedBot: boolean;
};

export type NavigatorInfo = {
  botScore: number;
  botScoreGroup: "0" | "1-29" | "30-35" | "36-40" | "41-45" | "46-99";
  country: string;
  ip: string;
  isBot: boolean;
  isLightHouseCrawler: boolean;
};
