import type { CheckoutCustomPayload } from "@product/rosetta-sdk";
import { useCallback } from "react";

export const useRosettaCheckoutCustomEventHandler = () => {
  const handleCustomEvent = useCallback((payload: CheckoutCustomPayload) => {
    const handleCheckoutCustomEventTracking = () => {
      const {
        eventName,
        params: { meter },
      } = payload;

      if (!eventName || !meter) return;

      switch (eventName) {
        case "meter-direct-checkout": {
          const {
            params: { action: eventAction },
          } = payload;
          if (!eventAction) return;

          break;
        }
      }
    };

    handleCheckoutCustomEventTracking();
  }, []);

  return {
    handleCustomEvent,
  };
};
